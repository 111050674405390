import HeroSection from "./component/HeroSection"
import NavBar from "./component/NavBar"

function App() {
  return (
    <div className="App">
      <NavBar />
      <HeroSection />
    </div>
  )
}

export default App
